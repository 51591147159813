import React, { FunctionComponent } from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { FlexibleContent } from '../../components/flexibleContent/FlexibleContent'
import { WpPage, WpSimpleProductConnection } from '../../../graphql-types'
import { ProductList } from '../../components/productList'

type DataProps = {
  wpPage: WpPage
  allWpSimpleProduct: WpSimpleProductConnection
}

const GroupsPage: FunctionComponent<PageProps<DataProps>> = ({ data }) => {
  const {
    wpPage: { flexibleContent, id, title },
    allWpSimpleProduct: { edges },
  } = data

  const onlineBookings = edges
    .filter(({ node: { courseDetails } }) => {
      return courseDetails?.bookingType === 'group'
    })
    .filter(({ node: { courseDetails } }) => {
      return courseDetails?.presentationMethod === 'Online'
    })

  const f2fBookings = edges
    .filter(({ node: { courseDetails } }) => {
      return courseDetails?.bookingType === 'group'
    })
    .filter(({ node: { courseDetails } }) => {
      return courseDetails?.presentationMethod === 'Face to face'
    })

  return (
    <Layout>
      <SEO title="Groups" />
      <Container maxW="4xl">
        <Grid gridGap="16">
          <Grid gridGap="8">
            <FlexibleContent {...flexibleContent} />
          </Grid>
          <Grid gridGap="8">
            {onlineBookings.length ? (
              <Grid gridGap="4">
                <Heading size="lg">Online courses</Heading>
                <Box backgroundColor="whiteAlpha.900" borderRadius="2" p="4">
                  <Grid gridGap="4">
                    {onlineBookings.map(({ node }, index) => {
                      return (
                        <ProductList
                          hasBooking={false}
                          isLast={index === onlineBookings.length - 1}
                          key={index}
                          {...node}
                        />
                      )
                    })}
                  </Grid>
                </Box>
              </Grid>
            ) : null}
            {f2fBookings.length ? (
              <Grid gridGap="4">
                <Heading size="lg">Face to face courses</Heading>
                <Box backgroundColor="whiteAlpha.900" borderRadius="2" p="4">
                  <Grid gridGap="4">
                    {f2fBookings.map(({ node }, index) => {
                      return (
                        <ProductList
                          hasBooking={false}
                          isLast={index === f2fBookings.length - 1}
                          key={index}
                          {...node}
                        />
                      )
                    })}
                  </Grid>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default GroupsPage

export const query = graphql`
  query groupsPage {
    wpPage(id: { eq: "cG9zdDo4ODQ=" }) {
      flexibleContent {
        content {
          ... on WpPage_Flexiblecontent_Content_Simple {
            align
            content {
              ... on WpPage_Flexiblecontent_Content_Simple_Content_Paragraph {
                fieldGroupName
                text
              }
              ... on WpPage_Flexiblecontent_Content_Simple_Content_List {
                fieldGroupName
                listItems {
                  fieldGroupName
                  listItem
                }
              }
            }
            fieldGroupName
            heading
            headingStyle
            subheading
            subheadingStyle
            link
            text
          }
          ... on WpPage_Flexiblecontent_Content_Quote {
            fieldGroupName
            quote
            caption
          }
          ... on WpPage_Flexiblecontent_Content_Image {
            fieldGroupName
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_Content_Columns {
            fieldGroupName
            template
            columnOne {
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Simple {
                align
                content {
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Simple_Content_Paragraph {
                    fieldGroupName
                    text
                  }
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Simple_Content_List {
                    fieldGroupName
                    listItems {
                      fieldGroupName
                      listItem
                    }
                  }
                }
                fieldGroupName
                heading
                headingStyle
                link
                subheading
                subheadingStyle
                text
              }
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnOne_Quote {
                caption
                fieldGroupName
                quote
              }
            }
            columnTwo {
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Simple {
                align
                content {
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Simple_Content_Paragraph {
                    fieldGroupName
                    text
                  }
                  ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Simple_Content_List {
                    fieldGroupName
                    listItems {
                      fieldGroupName
                      listItem
                    }
                  }
                }
                fieldGroupName
                heading
                headingStyle
                link
                subheading
                subheadingStyle
                text
              }
              ... on WpPage_Flexiblecontent_Content_Columns_ColumnTwo_Quote {
                caption
                fieldGroupName
                quote
              }
            }
          }
        }
      }
      id
      slug
      title
    }
    allWpSimpleProduct(sort: { fields: menuOrder }) {
      edges {
        node {
          databaseId
          id
          link
          menuOrder
          name
          onSale
          price
          regularPrice
          salePrice
          slug
          courseDetails {
            courseType {
              ... on WpCourse {
                id
                slug
                title
              }
            }
            sessions {
              duration
              startTime
            }
            bookingType
            locationShortname
            presentationMethod
          }
        }
      }
    }
  }
`
